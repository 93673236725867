import { EPerfil } from '../enums';
import { Modulo } from './Modulo';

export class Perfil {
	perfilID?: EPerfil;
	nome?: string;
	modulos?: Modulo[];

	constructor(dto: any) {
		this.perfilID = dto?.perfilID as EPerfil;
		this.nome = dto?.nome;
		this.modulos = dto?.modulos.map((modulo: any) => new Modulo(modulo));
	}
}