import React, { useCallback } from 'react';
import { Sidebar, Menu, MenuItem, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import { SidebarHeader } from './components/SidebarHeader';
import { SidebarFooter } from './components/SidebarFooter';
import { Typography } from './components/Typography';
import { Box, Icon } from '@mui/material';
import { useCollapseMenuContext, useToggleMenuContext } from '../../../contexts/MenuContext';
import { useAuthContext } from 'contexts';
import { Modulo } from 'models';
import { useNavigate } from 'react-router-dom';

type Theme = 'light' | 'dark';

const themes = {
	light: {
		sidebar: {
			backgroundColor: '#ffffff',
			color: '#607489',
		},
		menu: {
			menuContent: '#fbfcfd',
			icon: '#0098e5',
			hover: {
				backgroundColor: '#c5e4ff',
				color: '#44596e',
			},
			disabled: {
				color: '#9fb6cf',
			},
		},
	},
	dark: {
		sidebar: {
			backgroundColor: '#0b2948',
			color: '#8ba1b7',
		},
		menu: {
			menuContent: '#082440',
			icon: '#59d0ff',
			hover: {
				backgroundColor: '#00458b',
				color: '#b6c8d9',
			},
			disabled: {
				color: '#3e5e7e',
			},
		},
	},
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

interface ISDEMenu {
	children: React.ReactNode;
}

export const SDEMenu: React.FC<ISDEMenu> = ({ children }) => {
	const [broken, setBroken] = React.useState(false);
	const [rtl, setRtl] = React.useState(false);
	const [hasImage, setHasImage] = React.useState(false);
	const [theme, setTheme] = React.useState<Theme>('light');

	const { toggled, toggleMenu } = useToggleMenuContext();
	const { collapsed, toggleCollapse } = useCollapseMenuContext();

	const { modulos } = useAuthContext();

	const navigate = useNavigate();

	const menuItemStyles: MenuItemStyles = {
		root: {
			fontSize: '13px',
			fontWeight: 400,
		},
		icon: {
			color: themes[theme].menu.icon,
			[`&.${menuClasses.disabled}`]: {
				color: themes[theme].menu.disabled.color,
			},
		},
		SubMenuExpandIcon: {
			color: '#b6b7b9',
		},
		subMenuContent: ({ level }) => ({
			backgroundColor:
				level === 0
					? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
					: 'transparent',
		}),
		button: {
			[`&.${menuClasses.disabled}`]: {
				color: themes[theme].menu.disabled.color,
			},
			'&:hover': {
				backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? .8 : 1),
				color: themes[theme].menu.hover.color,
			},
		},
		label: ({ open }) => ({
			fontWeight: open ? 700 : undefined,
		}),
	};

	const Modulos = useCallback(() => {
		return (
			<>
				{
					modulos
						?.sort((a, b) => (a.ordem! < b.ordem!) ? 1 : -1)
						.map(({ moduloID, nome, icone, rota }: Modulo) =>
							<MenuItem onClick={() => navigate(`/${rota}`)} key={moduloID} icon={<Icon>{icone}</Icon>}>{nome}</MenuItem>
						)
				}
			</>
		);
	}, [modulos]);

	return (
		<Box style={{ display: 'flex', height: '100%', minHeight: '100vh', direction: rtl ? 'rtl' : 'ltr' }}>
			<Sidebar
				collapsed={collapsed}
				toggled={toggled}
				onBackdropClick={() => toggleMenu()}
				onBreakPoint={setBroken}
				image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
				rtl={rtl}
				breakPoint="md"
				backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
				rootStyles={{
					color: themes[theme].sidebar.color,
					zIndex: 100
				}}
			>
				<Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} />
					<Box style={{ flex: 1, marginBottom: '32px' }}>
						<Box style={{ padding: '0 24px', marginBottom: '8px' }}>
							<Typography
								variant="body2"
								fontWeight={600}
								style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
							>
								Geral
							</Typography>
						</Box>
						<Menu menuItemStyles={menuItemStyles}>
							<Modulos />
							{/* <SubMenu
								label="Charts"
								icon={<BarChart />}
								suffix={
									<Badge variant="danger" shape="circle">
										6
									</Badge>
								}
							>
								<MenuItem> Pie charts</MenuItem>
								<MenuItem> Line charts</MenuItem>
								<MenuItem> Bar charts</MenuItem>
							</SubMenu>
							<SubMenu label="Maps" icon={<Global />}>
								<MenuItem> Google maps</MenuItem>
								<MenuItem> Open street maps</MenuItem>
							</SubMenu>
							<SubMenu label="Theme" icon={<InkBottle />}>
								<MenuItem> Dark</MenuItem>
								<MenuItem> Light</MenuItem>
							</SubMenu>
							<SubMenu label="Components" icon={<Diamond />}>
								<MenuItem> Grid</MenuItem>
								<MenuItem> Layout</MenuItem>
								<SubMenu label="Forms">
									<MenuItem> Input</MenuItem>
									<MenuItem> Select</MenuItem>
									<SubMenu label="More">
										<MenuItem> CheckBox</MenuItem>
										<MenuItem> Radio</MenuItem>
									</SubMenu>
								</SubMenu>
							</SubMenu>
							<SubMenu label="E-commerce" icon={<ShoppingCart />}>
								<MenuItem> Product</MenuItem>
								<MenuItem> Orders</MenuItem>
								<MenuItem> Credit card</MenuItem>
							</SubMenu> */}
						</Menu>

						{/* <Box style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px' }}>
							<Typography
								variant="body2"
								fontWeight={650}
								style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
							>
								Extra
							</Typography>
						</Box>

						<Menu menuItemStyles={menuItemStyles}>
							<MenuItem icon={<Calendar />} suffix={<Badge variant="success">New</Badge>}>
								Calendar
							</MenuItem>
							<MenuItem icon={<Book />}>Documentation</MenuItem>
							<MenuItem disabled icon={<Service />}>
								Examples
							</MenuItem>
						</Menu> */}
					</Box>
					<SidebarFooter collapsed={collapsed} />
				</Box>
			</Sidebar>
			{!collapsed && <Box position='fixed' top='0' left='0' bottom={0} right={0} zIndex={99} bgcolor='#000' sx={{ opacity: .5 }} onClick={toggleCollapse} />}
			{children}
		</Box>
	);
};