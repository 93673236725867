export class Municipio {
	municipioID?: number;
	nome?: string;
	ufid?: number;
	ufSigla?: string;
	ufNome?: string;

	constructor(dto: any) {
		this.municipioID = dto?.municipioID;
		this.nome = dto?.nome;
		this.ufid = dto?.ufid;
		this.ufSigla = dto?.ufSigla;
		this.ufNome = dto?.ufNome;
	}
}