import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toast';
import { catchError, from, Observable, tap, throwError } from 'rxjs';
import { Empresa, Modulo, Perfil, Token, Usuario } from 'models';
import { AuthService } from 'shared/services/api/Auth/AuthService';

interface IAuthContextData {
	handleLogout: () => void;
	handleLogin: (email: string, senha: string) => Observable<void>;
	handleChangeEmpresa: (empresa: Empresa | undefined) => void;
	handleChangePerfil: (perfil: Perfil | undefined) => void;
	isAuthenticated: boolean;
	usuarioLogado?: Usuario;
	empresasUsuario: Empresa[];
	empresaSelecionada?: Empresa;
	perfilsUsuario: Perfil[];
	perfilSelecionado?: Perfil;
	modulos: Modulo[];
}

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
	const [accessToken, setAccessToken] = useState<string>();
	const [usuarioLogado, setUsuarioLogado] = useState<Usuario>();
	const [empresasUsuario, setEmpresasUsuario] = useState<Empresa[]>([]);
	const [empresaSelecionada, setEmpresaSelecionada] = useState<Empresa>();
	const [perfilsUsuario, setPerfilsUsuario] = useState<Perfil[]>([]);
	const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil>();
	const [modulos, setModulos] = useState<Modulo[]>([]);

	useEffect(() => {
		const dadosTokenUsuario = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!) || '{}');
		const { accessToken } = new Token(dadosTokenUsuario);
		const usuarioLocalStorage = new Usuario(dadosTokenUsuario);

		_setVariablesSystem(usuarioLocalStorage);
		setAccessToken(accessToken);
	}, []);

	const _setVariablesSystem = useCallback((usuario: Usuario) => {
		const { empresas = [] } = usuario;
		const empresa = empresas?.at(0) ?? {} as Empresa;
		const { perfils = [] } = empresa;
		const perfil = perfils?.at(0) ?? {} as Perfil;
		const { modulos = [] } = perfil;

		setUsuarioLogado(usuario);
		setEmpresasUsuario(empresas);
		setEmpresaSelecionada(empresas?.at(0));
		setPerfilsUsuario(perfils);
		setPerfilSelecionado(perfil);
		setModulos(modulos);
	}, []);

	const handleChangeEmpresa = useCallback(setEmpresaSelecionada, []);
	const handleChangePerfil = useCallback(setPerfilSelecionado, []);

	const handleLogin = useCallback((email: string, senha: string) => {
		return from(AuthService.authorization(email, senha))
			.pipe(
				tap((token: any) => {
					const { accessToken, tokenType, expires } = token;
					const expiresAt = new Date().getTime() + expires * 1000;
					localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!, JSON.stringify(token));
					toast.success(`Login realizado com sucesso`, {
						backgroundColor: '#006400'
					});
					setAccessToken(accessToken);
					_setVariablesSystem(new Usuario(token));
				}),
				catchError((error) => {
					return throwError(() => error);
				})
			);
	}, []);

	const handleLogout = useCallback(() => {
		localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
		setAccessToken(undefined);
	}, []);

	const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

	return (
		<AuthContext.Provider value={{
			handleLogin,
			handleLogout,
			handleChangeEmpresa,
			handleChangePerfil,

			isAuthenticated,
			usuarioLogado,
			empresasUsuario,
			empresaSelecionada,
			perfilsUsuario,
			perfilSelecionado,
			modulos
		}}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = (): IAuthContextData => useContext<IAuthContextData>(AuthContext);
