import React, { useCallback, useState } from 'react';
import { Box, CircularProgress, FormControl, Icon, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'contexts';
import { Empresa } from 'models';


interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	collapsed?: boolean;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children, collapsed, ...rest }) => {
	const [empresaid] = useState();

	const { usuarioLogado, empresasUsuario, empresaSelecionada, handleChangeEmpresa } = useAuthContext();

	console.log('empresaSelecionada', empresaSelecionada);

	const handleChange = (event: SelectChangeEvent) => {
		const empresa = empresasUsuario.find(({ empresaID }: Empresa) => empresaID === parseInt(event.target.value));
		handleChangeEmpresa(empresa);
	};

	const EmpresaSelect = useCallback(() => {
		if (empresasUsuario.length == 1) {
			handleChangeEmpresa(empresasUsuario.at(0));
			return null;
		}

		return (
			<>
				<InputLabel id='demo-select-small-label'>Empresas</InputLabel>
				<Select
					labelId='demo-select-small-label'
					id='demo-select-small'
					value={empresaid}
					label='Empresa'
					onChange={handleChange}
				>
					<MenuItem key={999} value="0">Selecione</MenuItem>
					{
						empresasUsuario?.map((empresa: Empresa, index: number) => {
							const { empresaID, nomeFantasia } = empresa;

							const selecionada = empresaSelecionada?.empresaID == empresaID;

							return <MenuItem key={index} value={empresaID} sx={{ fontWeight: selecionada ? 'bold' : 'normal' }}>{nomeFantasia}</MenuItem>;
						})
					}
				</Select>
			</>
		);
	}, [empresasUsuario]);

	return (
		<Box
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginBottom: '40px',
			}}
		>
			<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
				<Table sx={{ border: 0 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2} sx={{ border: 0 }}>

							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody sx={{ border: 0 }}>
						<TableRow>
							<TableCell sx={{ border: 0 }}>
								<FormControl sx={{ width: '100%' }} size='small'>
									<EmpresaSelect />
								</FormControl>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableFooter sx={{ border: 0 }}>
						<TableRow>
							<TableCell sx={{ border: 0 }}>
								<Box>
									<Link
										className='sidebar-btn'
										style={{
											cursor: 'pointer',
											textDecoration: 'none',
											color: 'rgba(255, 255, 255, 0.9)',
											flex: 1,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											background: '#0098e5',
											padding: !collapsed ? '5px 20px 5px 20px' : '5px',
											borderRadius: '50px',
										}}
										to='/profile'
									>
										<Icon fontSize={collapsed ? 'large' : 'medium'}>account_circle</Icon>
										{!collapsed && <Typography paddingLeft={1} variant='h6'>{usuarioLogado?.nome}</Typography>}
									</Link>
								</Box>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};
