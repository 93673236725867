export class Token {
	accessToken?: string;
	tokenType?: string;
	expires?: number;

	constructor(dto: any) {
		this.accessToken = dto?.accessToken;
		this.tokenType = dto?.tokenType;
		this.expires = dto?.expires;
	}
}