import axios, { InternalAxiosRequestConfig } from 'axios';
import { errorInterceptor, responseInterceptor } from './interceptors';
import { Token } from 'models';

const Api = axios.create({
	baseURL: process.env.REACT_APP_URL_BASE,
	adapter: 'fetch',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});

Api.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error),

);

Api.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!) || '{}') as Token;
		if (!config.url?.includes('auth/token'))
			config.headers.setAuthorization(`Bearer ${token.accessToken}`);

		return config;
	},
	error => error,
);

export { Api };