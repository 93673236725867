import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { ToastContainer, toast } from 'react-toast';
// import { Bounce, ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts';
import { Login } from './pages';

import 'react-toastify/dist/ReactToastify.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { MenuProvider } from './contexts/MenuContext';

export const App = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<MenuProvider>
					<Login>
						<AppRoutes />
					</Login>
					<ToastContainer delay={3000} />
				</MenuProvider>
			</AuthProvider>
		</BrowserRouter>
	);
};
// export const App = () => {
// 	return (
// 		<BrowserRouter>
// 			<AppRoutes />
// 		</BrowserRouter>
// 	);
// };