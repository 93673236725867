import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	Stack,
	TableHead,
	Icon,
	Button,
	TablePagination,
	TableSortLabel,
	ThemeProvider,
	IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { Empresa } from 'models';
import { EOrdenacao, ESituacao } from 'enums';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Empresaservice } from 'shared/services/api/Empresas/EmpresasService';
import { visuallyHidden } from '@mui/utils';
import { createTheme, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: EOrdenacao,
	orderBy: Key,
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string },
) => number {
	return order === EOrdenacao.DESC
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

interface IEmpresaTable {
	empresaID: number;
	nomeFantasia: string;
	cpfcnpj: string;
	municipio: string;
	situacao: string;
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof IEmpresaTable;
	label: string;
	numeric: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'empresaID',
		numeric: true,
		disablePadding: true,
		label: 'ID',
	},
	{
		id: 'nomeFantasia',
		numeric: false,
		disablePadding: false,
		label: 'Nome Fantasia',
	},
	{
		id: 'cpfcnpj',
		numeric: false,
		disablePadding: false,
		label: 'CPF/CNPJ',
	},
	{
		id: 'municipio',
		numeric: false,
		disablePadding: false,
		label: 'Munícipio',
	},
	{
		id: 'situacao',
		numeric: false,
		disablePadding: false,
		label: 'Situação',
	},
];

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IEmpresaTable) => void;
	order: EOrdenacao;
	orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof IEmpresaTable) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {

					const orderDirection = EOrdenacao[order].toLowerCase() as 'asc' | 'desc';
					const sortDirection = orderBy === headCell.id ? orderDirection : false;
					const direction = orderBy === headCell.id ? orderDirection : 'asc';
					const iconName = order == EOrdenacao.DESC ? 'sorted descending' : 'sorted ascending';

					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'left' : 'center'}
							padding='normal'
							sortDirection={sortDirection}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={direction}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{iconName}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}

				<TableCell
					key={9999}
					align='center'
					padding='normal'
				>
					&nbsp;
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export const Empresas: React.FC = () => {
	const { debounce } = useDebounce(300, false);
	const [isLoadingEmpresas, setIsLoadingEmpresas] = useState(true);
	const [rowsEmpresas, setRowsEmpresas] = useState<IEmpresaTable[]>([]);
	const navigate = useNavigate();

	const [ordenarPor, setOrdenarPor] = useState<EOrdenacao>(EOrdenacao.ASC);
	const [campoOrdenacao, setCampoOrdenacao] = useState<keyof IEmpresaTable>('empresaID');
	const [pagina, setPagina] = useState(0);
	const [total, setTotal] = useState(0);
	const [qtdPagina, setQtdPagina] = useState(parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!));
	const theme = useTheme();


	const handleRequestSort = (
		event: MouseEvent<unknown>,
		property: keyof IEmpresaTable,
	) => {
		const isAsc = campoOrdenacao === property && ordenarPor === EOrdenacao.ASC;
		setOrdenarPor(isAsc ? EOrdenacao.DESC : EOrdenacao.ASC);
		setCampoOrdenacao(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPagina(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQtdPagina(parseInt(event.target.value));
		setPagina(0);
	};

	const emptyRows =
		pagina > 0 ? Math.max(0, (1 + pagina) * (qtdPagina - rowsEmpresas.length)) : 0;

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales['ptBR']),
		[theme],
	);

	const getEmpresas = useCallback(() => {
		from(Empresaservice.getWithPagination(pagina, qtdPagina, campoOrdenacao, ordenarPor))
			.pipe(
				tap(() => setIsLoadingEmpresas(false)),
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					const { empresas, total } = retorno;

					return of({
						empresas: empresas
							.map(({
								empresaID,
								nomeFantasia,
								cpfcnpj,
								municipio,
								situacaoID
							}: Empresa) => ({
								empresaID,
								nomeFantasia,
								cpfcnpj,
								municipio: `${municipio?.nome}/${municipio?.ufSigla}`,
								situacao: ESituacao[situacaoID]
							} as IEmpresaTable)),
						total
					});
				})
			)
			.subscribe({
				next: ({ empresas, total }: any) => {
					setRowsEmpresas(empresas);
					setTotal(total);
				},
				error: (error) => console.log(error)
			});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	useEffect(() => {
		debounce(() => {
			getEmpresas();
		});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
					<Table sx={{ border: 0 }}>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2} sx={{ border: 0 }}>
									<Typography variant='h4' display='flex' justifyContent='center' alignItems='center' gutterBottom marginBottom={0}>
										Empresa
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody sx={{ border: 0 }}>
							<TableRow>
								<TableCell>
									<Box sx={{ flex: 1, justifyContent: 'center' }}>
										<Paper sx={{ mb: 2, alignSelf: 'center', marginX: '2vw' }}>
											<ThemeProvider theme={themeWithLocale}>
												<TableContainer>
													<Table
														sx={{ minWidth: 750 }}
														aria-labelledby='tableTitle'
														size='small'
													>
														<EnhancedTableHead
															order={ordenarPor}
															orderBy={campoOrdenacao}
															onRequestSort={handleRequestSort}
														/>
														<TableBody>
															{rowsEmpresas.map(({
																empresaID,
																nomeFantasia,
																cpfcnpj,
																municipio,
																situacao
															}, index) => {
																return (
																	<TableRow hover key={empresaID}>
																		<TableCell align='left'>{empresaID}</TableCell>
																		<TableCell align='center'>{nomeFantasia}</TableCell>
																		<TableCell align='center'>{cpfcnpj}</TableCell>
																		<TableCell align='center'>{municipio}</TableCell>
																		<TableCell align='center'>{situacao}</TableCell>
																		<TableCell align='center' width='15%'>
																			<IconButton color='error'><Icon>delete</Icon></IconButton>
																			<IconButton color='default'><Icon>edit</Icon></IconButton>
																			<IconButton color='info'><Icon>visibility</Icon></IconButton>
																		</TableCell>
																	</TableRow>
																);
															})}
															{emptyRows > 0 && (
																<TableRow
																	style={{
																		height: 33 * emptyRows,
																	}}
																>
																	<TableCell colSpan={6} />
																</TableRow>
															)}
														</TableBody>
													</Table>
												</TableContainer>
												<TablePagination
													rowsPerPageOptions={[5, 10, 25]}
													component='div'
													count={total}
													rowsPerPage={qtdPagina}
													page={pagina}
													onPageChange={handleChangePage}
													onRowsPerPageChange={handleChangeRowsPerPage}
												/>
											</ThemeProvider>
										</Paper>
									</Box>

									{/* <Stack direction='row' spacing={1} mt={2} marginX='10vw'>
										<TextField
											disabled={carregando}
											label='Como posso ajudar?'
											variant='outlined'
											fullWidth
											value={pergunta}
											onChange={(e) => setPergunta(e.target.value)}
											onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
										/>
										<Button
											disabled={carregando}
											color='success'
											disableElevation
											variant='contained'
											onClick={sendMessage} >
											<IconButton color='inherit' onClick={sendMessage}>
												<SendIcon />
											</IconButton>
										</Button>
									</Stack> */}

								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer >
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => navigate('/cadastrarEmpresa')}
					startIcon={<Icon>add</Icon>}
					sx={{ marginLeft: 2 }}>
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Cadastrar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
