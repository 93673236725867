import { Empresa } from 'models';
import { ESituacao } from '../../../../enums/eSituacao';
import { Api } from '../axios-config';
import { EOrdenacao } from 'enums';
import { ToQueryParams } from 'utils';

export interface IMunicipio {
	municipioID: number;
	ufid: number;
	ufSigla: string;
	ufNome: string;
}
export interface IListagemEmpresa {
	empresaID: number;
	nomeFantasia: string;
	bairro: string;
	cep: string;
	cpfcnpj: string;
	dataHoraAlteracao: string;
	dataHoraCadastro: string;
	email: string;
	logradouro: string;
	municipio: IMunicipio;
	numero: string;
	perfils: any[];
	razaoSocial: string;
	responsavel: string;
	situacaoID: ESituacao;
	usuarioIDCadastro: string;
}

export interface IDetalheEmpresa {
	empresaID: number;
	nome: string;
}

type TEmpresasComTotal = {
	empresas: Empresa[];
	total: number;
}

type TEmpresasSomenteTotalCount = {
	totalCount: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TEmpresasSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/Empresa/ObterQtdeTotal`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (page = 1, filter = ''): Promise<Empresa[] | Error> => {
	try {
		const urlRelativa = `/Empresa/ObterTodos`;
		const { data } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TEmpresasComTotal | Error> => {
	try {
		const queryParams = ToQueryParams({
			pagina: pagina + 1,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Empresa/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				empresas: data.data.lista?.map((empresa: any) => new Empresa(empresa)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheEmpresa | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const { data } = await Api.get(`/Empresa/ObterPorId?id_empresa=${id}`);

		if (data) {
			return data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

export const Empresaservice = {
	getTotal,
	getAll,
	getById,
	getWithPagination
};