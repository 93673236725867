import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';
import { useEffect } from 'react';

function AppWithCallbackAfterRender() {
	useEffect(() => {
		console.log('Renderizado');
	});

	return <App />;
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);