import { Routes, Route, Navigate } from 'react-router-dom';

import { DetalheDeArquivos, ListagemDeArquivos, ListagemDeArquivosAvulsos, ListagemDeArquivosProcessos, ListagemDeArquivosAvulsosDetalhado, ChatIA, Empresas, CadastrarEmpresa } from 'pages';
import { SDEMenu } from 'shared/components/menu/Sidebar';

export const AppRoutes = () => {
	return (
		<>
			<SDEMenu>
				<Routes>
					<Route path="pesquisar" element={<ListagemDeArquivos />} />
					<Route path="incluir/detalhe" element={<DetalheDeArquivos />} />
					<Route path="listararquivosavulsos" element={<ListagemDeArquivosAvulsos />} />
					<Route path="listararquivosprocessos" element={<ListagemDeArquivosProcessos />} />
					<Route path="listararquivosavulsosDetalhado/:id" element={<ListagemDeArquivosAvulsosDetalhado />} />
					<Route path="chatia" element={<ChatIA />} />
					<Route path="empresa" element={<Empresas />} />
					<Route path="cadastrarEmpresa" element={<CadastrarEmpresa />} />

					<Route path="*" element={<Navigate to="pesquisar" />} />
				</Routes>
			</SDEMenu>
		</>
	);
};
