import { EPermissao } from '../enums';

export class Modulo {
	moduloID?: number;
	nome?: string;
	icone?: string;
	ordem?: number;
	rota?: string;
	permissoes?: EPermissao[];

	constructor(dto: any) {
		this.moduloID = dto?.moduloID;
		this.nome = dto?.nome;
		this.icone = dto?.icone;
		this.ordem = dto?.ordem;
		this.rota = dto?.rota;
		this.permissoes = dto?.permissoes.map((permissao: any) => permissao as EPermissao);
	}
}