import React, { useState } from 'react';
import {
	Typography,
	TextField,
	IconButton,
	Icon,
	Box,
	Button,
	FormControl,
	InputAdornment,
	Card,
	CardContent,
	Stack,
	CardActions,
	CircularProgress,

} from '@mui/material';
import { toast } from 'react-toast';
import { from, switchMap, tap } from 'rxjs';
import * as yup from 'yup';
import { useAuthContext } from 'contexts';


const loginSchema = yup.object().shape({
	email: yup.string().email().required('O campo email é obrigatorio'),
	password: yup.string().min(5).required('O campo senha é obrigatoria')
});

interface ILoginProps {
	children: React.ReactNode;
}

export const Login: React.FC<ILoginProps> = ({ children }) => {
	const { isAuthenticated, handleLogin } = useAuthContext();

	const [mostrarSenha, setMostrarSenha] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const handleSubmit = () => {
		setIsLoading(true);
		from(loginSchema.validate({ email, password }, { abortEarly: false }))
			.pipe(
				switchMap(({ email, password }) => from(handleLogin(email, password)))
			)
			.subscribe({
				next: () => setIsLoading(false),
				error: (errors) => {
					if ('inner' in errors) {
						errors.inner.forEach((error: any) => {
							if (error.path === 'email') {
								setEmailError(error.message);
								setIsLoading(false);
							} else {
								setPasswordError(error.message);
								setIsLoading(false);
							}
						});
					} else {
						setIsLoading(false);
						toast.error(errors?.message || 'Erro ao realizar login', { backgroundColor: '#9b111e' });

					}
				}

			});
	};


	if (isAuthenticated) return (
		<>{children}</>
	);

	return (
		<Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center' bgcolor='#0A4F7F'>
			<Box
				position='absolute'
				display='flex'
				alignItems='center'
				marginBottom='2'
				top='10vh'
			>
				<img
					src='/Logo-arquivoteca-para-footer-min.png'
					alt='ARQUIVOTECA logo'
					style={{ top: 0 }}
				/>
			</Box>
			<Card sx={{ maxWidth: 400, width: '100%', borderRadius: 3 }}>
				<CardContent>
					<Box display='flex' flexDirection='column' gap={2}>
						<Typography variant='h6' align='center'>Identifique-se</Typography>
						<TextField
							fullWidth
							label='Email'
							type='email'
							value={email}
							disabled={isLoading}
							error={!!emailError}
							helperText={emailError}
							onKeyDown={() => setEmailError('')}
							onChange={e => setEmail(e.target.value)}
						/>
						<TextField
							fullWidth
							label='Senha'
							type={mostrarSenha ? 'text' : 'password'}
							value={password}
							disabled={isLoading}
							error={!!passwordError}
							helperText={passwordError}
							onKeyDown={() => setPasswordError('')}
							onChange={e => setPassword(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setMostrarSenha(!mostrarSenha)}
											edge="end"
											aria-label="toggle password visibility"
										>
											<Icon>
												{mostrarSenha ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Button
						disabled={isLoading}
						variant='contained'
						onClick={handleSubmit}
						endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
						fullWidth
						sx={{ mt: 2 }}
					>
						Login
					</Button>
					<Button
						variant="text"
						color="secondary"
						fullWidth
						onClick={() => {
							// handle forgot password logic
						}}
					>
						Esqueceu a senha?
					</Button>
				</CardContent>
			</Card>
		</Box>
	);
};
