import { Empresa } from './Empresa';

export class Usuario {
	usuarioID?: number;
	nome?: string;
	empresas?: Empresa[];

	constructor(dto: any) {
		this.usuarioID = dto?.usuarioID;
		this.nome = dto?.nome;
		this.empresas = dto?.empresas?.map((empresa: any) => new Empresa(empresa));
	}
}