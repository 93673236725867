import React, { useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	Stack,
	TableHead,
	Icon,
	Button,
	FormLabel,
	OutlinedInput,
	FormControlLabel,
	Checkbox,
	styled
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';

const FormGrid = styled(Grid)(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const CadastrarEmpresa: React.FC = () => {
	const [razaoSocial, setRazaoSocial] = useState<string>('');
	const [nomeFantasia, setNomeFantasia] = useState<string>('');
	const [responsavel, setResponsavel] = useState<string>('');
	const [cpfCnpj, setCpfCnpj] = useState<string>('');
	const [logradouro, setLogradouro] = useState<string>('');
	const [cep, setCep] = useState<string>('');
	const [numero, setNumero] = useState<string>('');
	const [bairro, setBairro] = useState<string>('');
	const [complemento, setComplemento] = useState<string>('');
	const [municipio, setMunicipio] = useState<string>('');
	const [estado, setEstado] = useState<string>('');
	const [telefoneCelular, setTelefoneCelular] = useState<string>('');
	const [telefoneFixo, setTelefoneFixo] = useState<string>('');
	const [email, setEmail] = useState<string>('');

	const handleRazaoSocial = (event: { target: { value: React.SetStateAction<string> }; }) => setRazaoSocial(event.target.value);
	const handleNomeFantasia = (event: { target: { value: React.SetStateAction<string> }; }) => setNomeFantasia(event.target.value);
	const handleResponsavel = (event: { target: { value: React.SetStateAction<string> }; }) => setResponsavel(event.target.value);
	const handleCpfCnpj = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
			if (value.length <= 6)
				formattedValue = value.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
			else if (value.length <= 9)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
			else if (value.length <= 10)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
			setCpfCnpj(formattedValue);
		} else if (value.length <= 14) {
			let formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g, '$1.$2.$3/$4-$5');
			if (value.length <= 12)
				formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/g, '$1.$2.$3/$4');
			setCpfCnpj(formattedValue);
		}
	};
	const handleLogradouro = (event: { target: { value: React.SetStateAction<string> }; }) => setLogradouro(event.target.value);
	const handleCep = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 8) {
			const formattedValue = value.replace(/(\d{5})(\d{1,3})/g, '$1-$2');
			setCep(formattedValue);
		}
	};
	const handleNumero = (event: { target: { value: React.SetStateAction<string> }; }) => setNumero(event.target.value);
	const handleBairro = (event: { target: { value: React.SetStateAction<string> }; }) => setBairro(event.target.value);
	const handleComplemento = (event: { target: { value: React.SetStateAction<string> }; }) => setComplemento(event.target.value);
	const handleMunicipio = (event: { target: { value: React.SetStateAction<string> }; }) => setMunicipio(event.target.value);
	const handleEstado = (event: { target: { value: React.SetStateAction<string> }; }) => setEstado(event.target.value);
	const handleTelefoneCelular = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
			if (value.length <= 10)
				formattedValue = value.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
			setTelefoneCelular(formattedValue);
		}
	};
	const handleTelefoneFixo = (event: { target: { value: React.SetStateAction<string> }; }) => setTelefoneFixo(event.target.value);
	const handleEmail = (event: { target: { value: React.SetStateAction<string> }; }) => setEmail(event.target.value);

	// const handleCardNumberChange = (event: { target: { value: string } }) => {
	// 	const value = event.target.value.replace(/\D/g, '');
	// 	const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
	// 	if (value.length <= 16) {
	// 		setCardNumber(formattedValue);
	// 	}
	// };

	// const handleCvvChange = (event: { target: { value: string } }) => {
	// 	const value = event.target.value.replace(/\D/g, '');
	// 	if (value.length <= 3) {
	// 		setCvv(value);
	// 	}
	// };

	// const handleExpirationDateChange = (event: { target: { value: string } }) => {
	// 	const value = event.target.value.replace(/\D/g, '');
	// 	const formattedValue = value.replace(/(\d{2})(?=\d{2})/, '$1/');
	// 	if (value.length <= 4) {
	// 		setExpirationDate(formattedValue);
	// 	}
	// };

	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
					<Table sx={{ border: 0 }}>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2} sx={{ border: 0 }}>
									<Typography variant='h4' display='flex' justifyContent='left' alignItems='center' gutterBottom marginBottom={0}>
										Cadastrar Empresa
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody sx={{ border: 0 }}>
							<TableRow>
								<TableCell>
									<Box sx={{ flex: 1, justifyContent: 'center' }}>
										<Paper elevation={0} sx={{ mb: 2, alignSelf: 'center', marginX: '5vw' }}>
											<Grid container spacing={3}>
												<FormGrid size={{ xs: 12, md: 5 }}>
													<FormLabel htmlFor="razao-social" required>
														Razão Social
													</FormLabel>
													<OutlinedInput
														id="razao-social"
														name="razao-social"
														type="name"
														placeholder="Razão Social"
														autoComplete="Razão Social"
														required
														value={razaoSocial}
														onChange={handleRazaoSocial}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 12, md: 5 }}>
													<FormLabel htmlFor="nome-fantasia" required>
														Nome Fantasia
													</FormLabel>
													<OutlinedInput
														id="nome-fantasia"
														name="nome-fantasia"
														type="nome-fantasia"
														placeholder="Nome Fantasia"
														autoComplete="Nome Fantasia"
														required
														value={nomeFantasia}
														onChange={handleNomeFantasia}
														size="small"
													/>
												</FormGrid>
												<FormGrid>
													<FormLabel htmlFor="cpfcnpj" required>
														CPF ou CNPJ
													</FormLabel>
													<OutlinedInput
														id="cpfcnpj"
														name="cpfcnpj"
														type="cpfcnpj"
														placeholder="CPF ou CNPJ"
														autoComplete="CPF ou CNPJ"
														required
														value={cpfCnpj}
														onChange={handleCpfCnpj}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 6 }}>
													<FormLabel htmlFor="responsavel">Responsável</FormLabel>
													<OutlinedInput
														id="responsavel"
														name="responsavel"
														type="responsavel"
														placeholder="Responsável"
														autoComplete="Responsável"
														required
														value={responsavel}
														onChange={handleResponsavel}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 6 }}></FormGrid>
												<FormGrid size={{ xs: 2 }}>
													<FormLabel htmlFor="cep" required>
														CEP
													</FormLabel>
													<OutlinedInput
														id="cep"
														name="cep"
														type="cep"
														placeholder="00000-000"
														autoComplete="CEP"
														required
														value={cep}
														onChange={handleCep}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 6 }}></FormGrid>
												<FormGrid size={{ xs: 6 }}>
													<FormLabel htmlFor="logradouro" required>
														Logradouro
													</FormLabel>
													<OutlinedInput
														id="logradouro"
														name="logradouro"
														type="logradouro"
														placeholder="Logradouro"
														autoComplete="Logaradouro"
														required
														value={logradouro}
														onChange={handleLogradouro}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 2 }}>
													<FormLabel htmlFor="numero" required>
														Número
													</FormLabel>
													<OutlinedInput
														id="numero"
														name="numero"
														type="numero"
														placeholder="Número"
														autoComplete="Número"
														required
														value={numero}
														onChange={handleNumero}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 4 }}>
													<FormLabel htmlFor="bairro" required>
														Bairro
													</FormLabel>
													<OutlinedInput
														id="bairro"
														name="bairro"
														type="bairro"
														placeholder="Bairro"
														autoComplete="Bairro"
														required
														value={bairro}
														onChange={handleBairro}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 6 }}>
													<FormLabel htmlFor="complemento">
														Complemento
													</FormLabel>
													<OutlinedInput
														id="complemento"
														name="complemento"
														type="complemento"
														placeholder="Complemento"
														autoComplete="Complemento"
														value={complemento}
														onChange={handleComplemento}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 2 }}>
													<FormLabel htmlFor="estado" required>
														Estado
													</FormLabel>
													<OutlinedInput
														id="estado"
														name="estado"
														type="estado"
														placeholder="Estado"
														autoComplete="Estado"
														required
														value={estado}
														onChange={handleEstado}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 3 }}>
													<FormLabel htmlFor="municipio" required>
														Município
													</FormLabel>
													<OutlinedInput
														id="municipio"
														name="municipio"
														type="municipio"
														placeholder="Município"
														autoComplete="Município"
														required
														value={municipio}
														onChange={handleMunicipio}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 3 }}>
													<FormLabel htmlFor="telefone-celular" required>
														Telefone Celular
													</FormLabel>
													<OutlinedInput
														id="telefone-celular"
														name="telefone-celular"
														type="telefone-celular"
														placeholder="Telefone Celular"
														autoComplete="Telefone Celular"
														value={telefoneCelular}
														onChange={handleTelefoneCelular}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 3 }}>
													<FormLabel htmlFor="telefone-fixo" required>
														Telefone Fixo
													</FormLabel>
													<OutlinedInput
														id="telefone-fixo"
														name="telefone-fixo"
														type="telefone-fixo"
														placeholder="Telefone fixo"
														autoComplete="Telefone fixo"
														value={telefoneFixo}
														onChange={handleTelefoneFixo}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 3 }}>
													<FormLabel htmlFor="email" required>
														E-mail
													</FormLabel>
													<OutlinedInput
														id="email"
														name="email"
														type="email"
														placeholder="E-mail"
														autoComplete="E-mail"
														required
														value={email}
														onChange={handleEmail}
														size="small"
													/>
												</FormGrid>
												<FormGrid size={{ xs: 12 }}>
													<FormControlLabel
														control={<Checkbox name="saveAddress" value="yes" />}
														label="Use this address for payment details"
													/>
												</FormGrid>
											</Grid>
										</Paper>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer >
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'end', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
